//
// general.scss
//

/*********************************/
/*         General                */
/*===============================*/
body {
    font-family: $font-family-secondary;
    overflow-x: hidden !important;
    font-size: $font-size-base;
    color: $dark !important;
}
h1, h2, h3, h4, h5, h6, .h1, .h2, .h3, .h4, .h5, .h6 {
    font-family: $font-family-base;
    line-height: 1.5;
    font-weight: 500 !important;
}
.display-1, .display-2, .display-3, .display-4, .display-5, .display-6 {
    line-height: 1.2;
}
::selection {
    background: lighten($dark, 5%);
    color: $white;
}
a {
    text-decoration: none !important;
}
p {
    line-height: 1.6;
}
ul li{
    -webkit-margin-start: 0.25rem;
            margin-inline-start: 0.25rem;
  }
  ul li:first-child{
    -webkit-margin-start: 0px;
            margin-inline-start: 0px;
  }