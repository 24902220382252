//
// _features.scss
//

/*********************************/
/*         Features              */
/*===============================*/
.features {
    .title {
        &:hover {
            color: $primary !important;
        }
    }
    .feature-icon {
        .hexagon {
            color: transparent !important;
            fill: rgba($primary, 0.05) !important;
            height: 102px;
            width: 102px;
        }
    }
}

.slider {
    display: flex;
    align-items: center;
    position: relative;
}

.slide {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    transition: opacity 0.5s ease-in-out;
}

.slide.active {
    opacity: 1;
}

.slide img {
    width: 100%;
    height: auto;
}

.slide-content {
    position: absolute;
    bottom: 20px;
    left: 20px;
    color: #fff;
}

.prev-btn,
.next-btn {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    background-color: transparent;
    border: none;
    color: #fff;
    font-size: 24px;
    cursor: pointer;
    transition: color 0.3s ease-in-out;
}

.prev-btn:hover,
.next-btn:hover {
    color: #ff6b6b;
}

.prev-btn {
    left: 20px;
}

.next-btn {
    right: 20px;
}

/*********************************/
/*         Property Listing      */
/*===============================*/
.property {
    transition: all 0.5s ease;
    .property-image {
        img,
        .property-icon {
            transition: all 0.5s ease;
            li {
                position: relative;
                margin-left: 0;
            }
        }
        .property-icon {
            position: absolute;
            top: 15px;
            right: 15px;
            opacity: 0;
        }
    }
    &:hover {
        box-shadow: $shadow-md !important;
        img {
            transform: scale(1.05);
        }
        .property-icon {
            opacity: 1;
        }
    }
    .title {
        &:hover {
            color: $primary !important;
        }
    }

    &.property-list {
        .property-image {
            img {
                object-fit: cover!important;

                @media (min-width: 768px) {
                    width: 12rem!important;
                }
            }
            &.md-full {
                img {
                    @media (max-width: 768px) {
                        width: 100% !important;
                    }
                }
            }
        }
    }
}

.categories {
    transition: all 0.5s ease;
    .title {
        &:hover {
            color: $primary !important;
        }
    }
    &:hover {
        box-shadow: $shadow-md !important;
    }
}